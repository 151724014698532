<template>
  <div class="flex flex-col gap-3">
    <p class="font-semibold text-xl">Sesiones pasadas</p>
    <p v-if="!pastSessions">No hay sesiones pasadas</p>
    <div v-else-if="pastSessions" class="flex justify-between gap-2 items-center bg-tp-yellow text-tp-black font-semibold py-3 px-4 rounded-xl" @click="handleRedirection">
      <p>Número de sesiones pasadas: <span class="font-bold pl-2">{{ pastSessionsInfo.length }}</span></p>
      <DJActionButton :darkbg="true" :image=arrow />
    </div>
  </div>
</template>
  
  <script>
  import arrow from '@/assets/images/arrow-right-yellow.png';
  import DJActionButton from './DJActionButton.vue';
  import { useRouter } from 'vue-router';

  export default {
    name: 'DJPastSessionsSection',
    components: {
      DJActionButton
    },
    props: {
      pastSessionsInfo: Object,
    },
    setup(props) {
      const router = useRouter();

      const pastSessions = props.pastSessionsInfo.length>0;

      const handleRedirection = () => {
        router.push('/dj/past-sessions');
      }


      return {
        arrow,
        pastSessions,
        handleRedirection
      };
    }
  }
  
  </script>