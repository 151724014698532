<template>
  <div class="flex flex-col items-start text-tp-yellow">
    <label class="text-sm font-semibold" for="input">{{ label }}</label>
    <div class="flex justify-between w-full">
      <div class="flex items-center gap-2 w-full">
        <img :src=icon alt="" class="w-3.5 h-3.5">
        <input class="bg-tp-black text-sm focus:outline-none focus:ring-0 font-medium w-full" type="text" id="input" v-model="localModel" disabled>
      </div>
      <DJActionButton v-if ="actionIcon" :image=actionIcon />
    </div>
  </div>
</template>
  
  <script>
  import DJActionButton from '@/components/DJ/DJActionButton.vue';
  import { ref, watch, toRefs } from 'vue';

  export default {
    name: 'DJInput',
    components: {
      DJActionButton
    },
    props: {
      icon: String,
      label: String,
      model: String,
      actionIcon: String
    },
  setup(props) {
      const { model } = toRefs(props);
      const localModel = ref(model.value);

      // Watch for changes in the prop and update the local data property
      watch(model, (newValue) => {
        localModel.value = newValue;
      });

      return {
        localModel
      };
    }
  }
  
  </script>