<template>
  <NavBar />
  <div class="px-8 pt-4 pb-10">
    <div class="flex flex-col gap-6" :class="{'z-20': !isCreateSessionModalVisible}">
      <p class="font-extrabold text-2xl">Zona privada</p>
      <DJProfile 
        v-if="detailsLoaded" 
        :djData = "profileData"
      />
      <DJActiveSessionSection 
        v-if="detailsLoaded" 
        class="cursor-pointer" 
        :class="{'z-20': !isCreateSessionModalVisible}" 
        :activeInfo = "loungeDetails" 
        :activeSession = "isThereActiveSession" 
        @onOpenModal="openCreateSessionModal" 
      />
      <DJPastSessionsSection 
        v-if="detailsLoaded" 
        class="cursor-pointer" :class="{'z-20': !isCreateSessionModalVisible}"
        :pastSessionsInfo = "sessionsInfo.past_sessions" 
      />
    </div>

    <DJCreateSessionModal
      v-if="isCreateSessionModalVisible" 
      :class="[{'transition-opacity duration-300 opacity-100 z-20': isCreateSessionModalVisible}, {'opacity-0': !isCreateSessionModalVisible}]"
      @onCloseModal="closeCreateSessionModal" 
    />

  </div>
</template>
  
  
  <script>
  import { ref, onMounted } from 'vue';
  import NavBar from '@/components/Common/NavBar.vue';
  import DJProfile from '@/components/DJ/DJProfile.vue';
  import DJActiveSessionSection from '../../components/DJ/DJActiveSessionSection.vue';
  import DJPastSessionsSection from '../../components/DJ/DJPastSessionsSection.vue';
  import DJCreateSessionModal from '../../components/DJ/DJCreateSessionModal.vue';
  import { getDJPrivateInfo, getSessionsInfo} from '../../service/djService';
  import createLoungeService from '@/service/loungeService';


  export default {
    name: 'DJPrivateArea',
    components: {
      NavBar,
      DJProfile,
      DJActiveSessionSection,
      DJCreateSessionModal,
      DJPastSessionsSection
    },
    setup() {
    
      const isCreateSessionModalVisible = ref(false);
      const profileData = ref(null);
      const detailsLoaded=ref(false);
      const sessionsInfo = ref (null);
      const loungeId = ref (null);
      const { fetchLoungeDetails } = createLoungeService();
      const loungeDetails = ref (null);
      const isThereActiveSession = ref(false);

      const openCreateSessionModal = () => {
        isCreateSessionModalVisible.value = true
      };

      const closeCreateSessionModal = () => {
        isCreateSessionModalVisible.value = false
      };

      const fetchDetails = async () => {
        if (!loungeId.value) return;
        const details = await fetchLoungeDetails(loungeId.value);
        if (details && details.public) {
          loungeDetails.value = details;
        }
        else{
          console.error('Detalles de lounge inválidos o incompletos:', details);
        }
      };

      onMounted(async () => { 
        profileData.value = await getDJPrivateInfo();
        sessionsInfo.value = await getSessionsInfo();
        if (sessionsInfo.value.current_session !== null){
          isThereActiveSession.value = true;
          if (sessionsInfo.value.current_session.loungeData.hexID){
            loungeId.value = sessionsInfo.value.current_session.loungeData.hexID
          }
          await fetchDetails();
          detailsLoaded.value=true;
        }
        else{

          isThereActiveSession.value = false;
          detailsLoaded.value=true;
        }
      });

      return{
        isCreateSessionModalVisible,
        openCreateSessionModal,
        closeCreateSessionModal,
        profileData,
        detailsLoaded,
        sessionsInfo,
        fetchDetails,
        loungeDetails,
        isThereActiveSession
      }
    }
  }
  
  </script>
  