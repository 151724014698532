<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
      <div class="relative mx-8">
        <div class="flex flex-col gap-8 items-center justify-center border-4 border-tp-yellow bg-tp-black rounded-2xl p-10">
          <img class="absolute w-4 h-4 top-5 right-5 z-10" :src="closeIcon" alt="close-icon" @click="handleCloseModal">
          <p class="text-tp-yellow text-4xl font-bold">Confirmación</p>
          <p class="font-semibold text-xl text-center">¿Estás seguro de que quieres votar por la canción {{ songName }}?</p>
          <div class="flex gap-4">
            <button @click="emitFreeVote" class="bg-tp-yellow text-tp-black font-bold text-lg px-10 py-2 rounded-xl">Si</button>
            <button @click="handleCloseModal" class="bg-tp-yellow text-tp-black font-bold text-lg px-10 py-2 rounded-xl">No</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import closeIcon from '@/assets/images/close-icon.png';
  
  export default {
    name: 'ConfirmGeneralVote',
    props: {
      songName: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const handleCloseModal = () => {
        emit('onCloseModal');
      };
  
      const emitFreeVote = () => {
        emit('onConfirmVote');
      };
  
      return {
        closeIcon,
        handleCloseModal,
        emitFreeVote
      };
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  </style>
  