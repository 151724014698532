<template>
  <NavBar/>
  <div class="flex flex-col items-center relative pb-6 px-4">
    <Timer v-if="detailsLoaded && timeLeft > 0" :initialTime="timeLeft" />
    <p class="font-semibold">¡Toca sobre la canción que más te guste!</p>
    <div class="flex justify-center w-full items-center px-8" :class="{'z-10': !boostModalVisible}">
      <img class="w-5 h-5 absolute -top-12 right-8" :src="info" @click="openPopup()" alt="">
    </div>
  
    <div v-if="!songsArr" class="grid justify-start grid-cols-1 xxs:grid-cols-2 gap-2 pt-6" :class="{'z-10': !boostModalVisible}">      
      <VotingSong v-for="(generalSong, index) in generalSongs" :key="index" :class="{'pointer-events-none': boostModalVisible}" :image="generalSong.image" :artist="generalSong.artist" :song="generalSong.songName" :progressPercentage="generalSong.progressPercentage" :selected="isVoted(generalSong.songId)" :showBoostButton="isVoted(generalSong.songId)" :position=index  @click="selectSong(generalSong)" @onBoostClick="handleBoostClick"/>
    </div>

    <InfoPopup :paragraphs="paragraphs" :isOpen="popupOpen" @closePopup="closePopup"/>

    <AddVotesModal 
      :votePlans="votePlans"
      @submitVotePlan="handlePayment" 
      @onCloseModal="handleCloseModal" 
      :class="[{'transition-opacity duration-300 opacity-100 z-10': boostModalVisible}, {'opacity-0': !boostModalVisible}]"/>

    <ConfirmGeneralVote v-if="confirmVoteModalVisible" :songName="selectedSongInfo.songName" @onCloseModal="closeConfirmModal" @onConfirmVote="confirmVote"/>

    <ConfirmFreeVoteModal
      v-if="confirmedVoteModalVisible" 
      @onCloseModal="handleCloseConfirmModal" 
      :class="[{'transition-opacity duration-300 opacity-100 z-20': confirmedVoteModalVisible}, {'opacity-0': !confirmedVoteModalVisible}]"
    />
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import NavBar from '@/components/Common/NavBar.vue';
import votingService from '../service/votingService';
import { songsInfo } from '../service/musicService';
import VotingSong from '@/components/GeneralVoting/VotingSong.vue';
import AddVotesModal from '@/components/Common/AddVotesModal.vue';
import Timer from '@/components/Common/Timer.vue';
import InfoPopup from '@/components/Common/InfoPopup.vue';
import info from '@/assets/images/info-icon.png';
import ConfirmGeneralVote from '../components/GeneralVoting/ConfirmGeneralVote.vue';
import ConfirmFreeVoteModal from '../components/Ranking/ConfirmFreeVoteModal.vue';
import { useVotePlans } from '@/composables/votePlans.js';

export default {
  name: 'GeneralVoting',
  components: {
    VotingSong,
    Timer,
    InfoPopup,
    AddVotesModal,
    NavBar,
    ConfirmGeneralVote,
    ConfirmFreeVoteModal
  },
  props: {
    songsArr: Array,
    loungeDetails: Object
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const popupOpen = ref(false);
    const ranking = ref(false);
    const selectedSong = ref('');
    const votedSong = ref(-1);
    const boostModalVisible = ref(false);
    const songs = ref([]);
    const loungeId = route.params.id;
    const loungeVotingService = votingService(loungeId);

    const generalSongs = ref([]);
    const detailsLoaded = ref(false);
    const details = ref(null);

    const confirmVoteModalVisible = ref(false);
    const selectedSongInfo = ref(null);
    const availableFreeBoost = ref(false);
    const confirmedVoteModalVisible = ref(false);

    const timeLeft = ref(null);
    const timerSetted = ref (false);

    const { votePlans } = useVotePlans();

    const refreshVotes = (songsData, songsResponse) => {
      const totalVotes = songsData.reduce((acc, song) => acc + song.votos, 0);
      generalSongs.value = songsResponse.map(song => {
        const songData = songsData.find(s => s.songID === song.songId);
        const progressPercentage = totalVotes > 0 ? Math.round((songData.votos / totalVotes) * 100) : 0;
        return {
          songId: song.songId,
          image: song.image,
          songName: song.songName,
          artist: song.artist,
          progressPercentage: progressPercentage
        };
      });
    };

    onMounted(async () => { 
      const loungeDetails = props.loungeDetails;
      if (loungeDetails && loungeDetails.public && loungeDetails.public.votingData) {
        details.value = loungeDetails;
        detailsLoaded.value = true;

        const songsData = Object.values(details.value.public.votingData.songs);
        const songIds = songsData.map(song => song.songID);

        if (songIds.length > 0) {
          const songsResponse = await songsInfo(songIds);
          refreshVotes(songsData, songsResponse);
        }
        timeLeft.value=props.loungeDetails.public.votingData.timeLeft
        timerSetted.value = true;

        ranking.value = Boolean(window.location.search.includes('ranking=true'));
      }

      if (loungeDetails && loungeDetails.private){
        if (loungeDetails.private.myVote !==-1){
          votedSong.value = loungeDetails.private.myVote
        }
        if (loungeDetails.private.availableFreeBoost){
          availableFreeBoost.value = loungeDetails.private.availableFreeBoost;
        }
      }
    });

    watch(() => props.loungeDetails, (newDetails) => {
      if (newDetails && newDetails.public && newDetails.public.votingData) {
        details.value = newDetails;
        const songsData = Object.values(details.value.public.votingData.songs);
        const songIds = songsData.map(song => song.songID);

        if (songIds.length > 0) {
          refreshVotes(songsData, generalSongs.value);
        }
      }
      if (newDetails && newDetails.private){
        if (newDetails.private.myVote){
          votedSong.value = newDetails.private.myVote;
        }
        if (newDetails.private.availableFreeBoost){
          availableFreeBoost.value = newDetails.private.availableFreeBoost;
        }
        timeLeft.value = newDetails.public.votingData.timeLeft;
        console.log(timeLeft.value)
      }
    }, { deep: true });

    const openPopup = () => {
      popupOpen.value = true;
    };

    const closePopup = () => {
      popupOpen.value = false;
    };

    const selectSong = (song) => {
      if (votedSong.value === -1){
        selectedSong.value = song.songId;
        selectedSongInfo.value = song;
        confirmVoteModalVisible.value = true;
      }
      else{
        if (votedSong.value === song.songId){
          boostModalVisible.value = true;
        }      
      }
    };

    const isVoted = (song) => {
      return votedSong.value === song;
    };

    const isNotVoted = (song) => {
      return votedSong.value !== song && votedSong.value !== -1;
    };

    const handleBoostClick = () => {
      boostModalVisible.value = true;
    };

    const handleCloseModal = () => {
      boostModalVisible.value = false;
    };

    const handlePayment = (votePlan) => {
      router.push(`/lounge/${loungeId}/song/${votedSong.value}/pay/${votePlan.price}/voting`);
    };

    const confirmVote = async () => {
      try {
        await loungeVotingService.vote(selectedSong.value);
        confirmVoteModalVisible.value = false;
        confirmedVoteModalVisible.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    const closeConfirmModal = () => {
      confirmVoteModalVisible.value = false;
    };

    const handleCloseConfirmModal = () => {
      confirmedVoteModalVisible.value = false;
    };

    return {
      info,
      selectedSong,
      votedSong,
      selectSong,
      isVoted,
      isNotVoted,
      boostModalVisible,
      handleBoostClick,
      handleCloseModal,
      songs,
      ranking,
      generalSongs,
      popupOpen,
      openPopup,
      closePopup,
      paragraphs: [ '¡Vota tu canción favorita!', '¡La que tenga más votos cuando se acabe el tiempo será la que suene!' ],
      details,
      detailsLoaded,
      handlePayment,
      votePlans,
      confirmVote,
      closeConfirmModal,
      confirmVoteModalVisible,
      selectedSongInfo,
      confirmedVoteModalVisible,
      handleCloseConfirmModal,
      timeLeft,
      timerSetted
    };
  }
};
</script>
