<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
        <div class="relative w-full px-6">
            <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center">
                <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
            </div>
            <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
                <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Crear sesión</p>
                <div class="flex flex-col gap-4">
                    <div class="flex flex-col gap-1 bg-tp-black px-4 py-2 text-tp-yellow rounded-lg border-2 border-tp-yellow mx-6">
                        <p class="text-sm">¿Usar tu QR o el de la sala?</p>
                        <div class="flex items-center justify-between gap-1">
                            <label class="flex gap-2 items-center cursor-pointer">
                                <span class="text-sm font-medium" :class="{ 'font-semibold': isIndependent }">DJ</span>
                                <div class="relative">
                                    <input type="checkbox" v-model="isIndependent" class="sr-only">
                                    <div class="block bg-tp-yellow w-10 h-6 rounded-full"></div>
                                    <div
                                        class="dot absolute left-1 top-1 bg-tp-black w-4 h-4 rounded-full transition-transform"
                                        :class="!isIndependent ? 'translate-x-full' : 'translate-x-0'"
                                    ></div>
                                </div>
                                <span class="text-sm font-medium" :class="{ 'font-semibold': !isIndependent }">Sala TuParty</span>
                            </label>
                        </div>
                    </div>
                    <form v-if ="!isIndependent" class="flex flex-col gap-8 w-full px-6" @submit.prevent="createSession">
                        <div class="flex flex-col gap-4">
                            <div class="flex flex-col bg-tp-black px-4 py-2 text-tp-yellow rounded-lg border-2 border-tp-yellow">
                                <div class="flex items-center gap-1">
                                    <img :src=hashtag alt="" class="w-4 h-4">
                                    <label class="text-sm" for="sessionId">Nº Sala</label>
                                </div>
                                <input class="bg-tp-black font-semibold focus:outline-none focus:ring-0" type="text" id="sessionId" v-model="sessionData.id" required>
                            </div>
                    
                            <div class="flex flex-col bg-tp-black px-4 py-2 text-tp-yellow rounded-lg border-2 border-tp-yellow">
                                <div class="flex items-center gap-1">
                                    <img :src=clock alt="" class="w-4 h-4">
                                    <label class="text-sm" for="sessionId">Hora límite</label>
                                </div>
                                <input class="bg-tp-black font-semibold focus:outline-none focus:ring-0" type="time" id="sessionTime" v-model="sessionData.time" required>
                            </div>
                        </div>
                
                        <button class="bg-tp-yellow text-tp-black p-2 rounded-lg" type="submit">Crear</button>
                    </form>
                    <form v-if ="isIndependent" class="flex flex-col gap-8 w-full px-6" @submit.prevent="createDJSession">           
                        <div class="flex flex-col bg-tp-black px-4 py-2 text-tp-yellow rounded-lg border-2 border-tp-yellow">
                            <div class="flex items-center gap-1">
                                <img :src=clock alt="" class="w-4 h-4">
                                <label class="text-sm" for="sessionId">Hora límite</label>
                            </div>
                            <input class="bg-tp-black font-semibold focus:outline-none focus:ring-0" type="time" id="sessionTime" v-model="sessionData.time" required>
                        </div>
                
                        <button class="bg-tp-yellow text-tp-black p-2 rounded-lg" type="submit">Crear</button>
                    </form>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import hashtag from '@/assets/images/hashtag.png';
    import clock from '@/assets/images/clock.png';
    import close from '@/assets/images/black-close-icon.png';
    import { createIndependentSession, createLoungeSession } from '../../service/djService';

    export default {
        name: 'DJCreateSessionModal',
        setup(props, { emit }) {
            const router = useRouter();
  
            const sessionData = ref({
                id: '',
                time: ''
            });

            const isIndependent = ref(true);
            const maxDuration = ref(null);
            

            const handleCloseModal = () => {
                emit('onCloseModal');
            }

            const createSession = async () => {
                const currentTime = new Date();

                const [hours, minutes] = sessionData.value.time.split(':').map(Number);

                const sessionTime = new Date();
                sessionTime.setHours(hours, minutes, 0, 0);

                const differenceInMs = sessionTime - currentTime;

                maxDuration.value = Math.floor(differenceInMs / 1000 / 60)+1;

                if (maxDuration.value<0){
                    maxDuration.value=maxDuration.value+1440
                }

                
                const response =  await createLoungeSession(sessionData.value.id, maxDuration.value);

                if (response.result === true){
                    router.push(`/dj/active-session/${sessionData.value.id}`);
                }
                else{
                    console.log(response.error)
                }
                
                
            }

            const createDJSession = async () => {
                const currentTime = new Date();

                const [hours, minutes] = sessionData.value.time.split(':').map(Number);

                const sessionTime = new Date();
                sessionTime.setHours(hours, minutes, 0, 0);

                const differenceInMs = sessionTime - currentTime;

                maxDuration.value = Math.floor(differenceInMs / 1000 / 60)+1;

                if (maxDuration.value<0){
                    maxDuration.value=maxDuration.value+1440
                }
                const response = await createIndependentSession(maxDuration.value);
                if (response.result === true){
                    router.push(`/dj/active-session/${response.message}`);
                }
                else{
                    console.log(response.message)
                }                
            }


            return {
                sessionData,
                handleCloseModal,
                createSession,
                hashtag,
                clock,
                close,
                isIndependent,
                createDJSession
            }
        }
    }
</script>

<style scoped>
.dot {
    transition: transform 0.3s ease;
}
.translate-x-full {
    transform: translateX(100%);
}
.translate-x-0 {
    transform: translateX(0);
}

input[type="time"]::-webkit-calendar-picker-indicator { display: none; } 

/* Contenedor del interruptor */
.toggle-checkbox {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Añadir posición relativa al label */
.toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 42px;
    height: 22px;
    background-color: #ffcc00; /* Siempre amarillo */
    border-radius: 9999px;
    padding: 3px;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

/* El "punto" que se desliza */
.toggle-label:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #0D0D0D;
    transition: transform 0.3s ease-in-out;
}

/* El "punto" que se desliza */
.toggle-checkbox:checked + .toggle-label:before {
    transform: translateX(0); /* Cambiar a 0 cuando esté activado */
}

/* El "punto" cuando no está activado (TuParty) */
.toggle-checkbox:not(:checked) + .toggle-label:before {
    transform: translateX(20px); /* Desliza la bolita a la derecha */
}



</style>