<template>
    <div class="flex justify-between items-center bg-tp-black border-2 border-tp-yellow p-4 rounded-xl w-full">
      <div class="flex flex-col gap-1 text-tp-yellow">
        <p v-for="(votingSong, index) in generalVoting.songs" :key="index" class="font-medium w-40 xs:w-52 truncate">
          <span class="font-semibold">{{ index + 1 }}.</span> {{ votingSong.songName }} - {{ votingSong.artist }}
        </p>
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex justify-center items-center bg-tp-yellow gap-1 py-2 px-3 rounded-full" @click="launchGeneralVoting">
            <p class="font-semibold text-tp-black">Lanzar</p>
            <img :src="clock" alt="" class="w-4 h-4">
        </div>
      </div>
    </div>
</template>

<script>
import clock from '@/assets/images/black-clock.png';
import pencil from '@/assets/images/pencil.png';

export default {
  name: 'DJPastSessions',
  props: {
    generalVoting: Object
  },
  setup(props, { emit }) {

    const launchGeneralVoting = () => {
      emit('launchGeneralVoting')
    }
    return {
      clock,
      pencil,
      launchGeneralVoting
    }
    }
  }

</script>