import { ref } from 'vue';

export function useVotePlans() {
  const votePlans = ref([
    {
      plan: 'TuParty Basic',
      price: 1.49,
      votes: 3
    },
    {
      plan: 'TuParty Advanced',
      price: 4.99,
      votes: 12
    },
    {
      plan: 'TuParty Pro',
      price: 9.99,
      votes: 25
    }
  ]);
  //también cambiar la función votes de stripepayment si se modifican las cantidades de votos
  return {
    votePlans
  };
}
