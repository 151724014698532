<template>
    <div class="relative w-full">
        <div class="flex justify-between items-center bg-tp-black py-2 px-5 rounded-xl" :class="isShouldPlay ? 'border-2 border-red-500' : 'border-2 border-tp-yellow'">
            <div class="flex items-center gap-3 text-tp-yellow">
                <p class="font-bold">{{ index }}</p>
                <img :src="rankingSong.image" alt="" class="w-12 h-12 rounded-full border-2 border-tp-yellow">
                <div class="flex flex-col font-semibold ">
                    <p class="text-lg leading-tight w-28 xs:w-40 truncate">{{ rankingSong.songName }}</p>
                    <p class="text-sm w-28 xs:w-40 truncate">{{ rankingSong.artist}}</p>
                    <p v-if ="!isShouldPlay" class="text-xs">{{rankingSong.revenue}} €</p>
                </div>
            </div>
            <div v-if ="!isShouldPlay" class="flex items-center justify-center w-14 rounded-full bg-tp-yellow">
                <p class="font-semibold text-sm py-1 text-tp-black">{{ rankingSong.votes }}</p>
            </div>
        </div>
        <button v-if ="!isGeneralVoting" class="absolute -right-2 -top-2 w-6 h-6 rounded-full bg-tp-yellow text-tp-black" @click="handleDeleteSong">-</button>
    </div>
</template>

<script>

export default {
    name: 'DJRankingSongCard',
    props: {
        rankingSong: Object,
        index: Number,
        isGeneralVoting: Boolean,
        rankingFinished: Boolean,
        isShouldPlay:Boolean
    },
    setup(props, { emit }) {
        const handleDeleteSong = () => {
            emit('deleteSong');
        }
        return {
            handleDeleteSong
        }
    }
}
</script>