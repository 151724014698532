import axios from 'axios';
import router from '../router';


const createApiService = () => {
  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_BASE_URL, 
  });

  
  axiosInstance.interceptors.response.use(
    response => response, 
    error => {
      if (error.response && error.response.status === 401) {        
        router.push('/dj/login');
      }
      else if (error.response && error.response.status === 403) {        
        router.push('/dj/reset-password');
      }
      return Promise.reject(error); 
    }
  );

  return axiosInstance;
};

export default createApiService;
