<template>
  <div class="relative">
    <NavBar />
    <DJActionButton class="absolute xxs:top-24 top-20 left-8" :image="arrowBack" @click="handleBack"/>
    <div class="flex flex-col gap-6 px-8 pt-4 pb-10">
      <p class="font-bold text-center text-2xl">4-Picks</p>
      <button class="bg-tp-yellow text-tp-black font-bold py-2 px-6 rounded-full w-max cursor-pointer" @click="handleCreateGeneralVoting">Crear</button>
      <DJGeneralVotingCard v-for="(poll, index) in polls" :key="index" :generalVoting="poll" 
        @launchGeneralVoting="launchGeneralVoting(poll)"/>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import NavBar from '@/components/Common/NavBar.vue';
import DJActionButton from '@/components/DJ/DJActionButton.vue';
import arrowBack from '@/assets/images/arrow-back.png';
import DJGeneralVotingCard from '@/components/DJ/DJGeneralVotingCard.vue';
import * as djService from '@/service/djService'

export default {
  name: 'DJGeneralVotings',
  components: {
    NavBar,
    DJActionButton,
    DJGeneralVotingCard
  },
  setup() {
    const polls = ref(null);
    const router = useRouter();
    const route = useRoute();
    const loungeId = route.params.id;

    const handleBack = () => {
      router.go(-1);
    };

    const launchGeneralVoting = async (poll) => {
      console.log(poll)
      let result = await djService.launchPrecreateVote(loungeId, poll.id)
      router.push(`/dj/active-session/${loungeId}`)
      console.log(result)
    }

    const handleCreateGeneralVoting = () => {
      router.push(`/dj/active-session/${loungeId}/create-general-voting`)
    };

    onMounted(async () => {
      polls.value = await djService.getPrecreateVote(loungeId);
    });

    return {
      arrowBack,
      handleBack,
      polls,
      launchGeneralVoting,
      handleCreateGeneralVoting
    };
  }
};
</script>
