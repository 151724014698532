<template>
  <div class="h-screen flex flex-col">
    <div class="relative bg-tp-black mb-8 px-3">
      <div class="relative flex justify-center w-full py-2 items-center">
        <p class="text-tp-yellow text-3xl xs:text-4xl font-bold pt-2">TuParty</p>
        <img class="w-6 absolute top-6 right-5" :src="info" @click="openPopup" alt="">
      </div>
      <div class="flex justify-center gap-2 w-full">
        <div v-if="rankingSongs[1]?.songId" class="flex flex-col items-center gap-2 mt-10">
          <img :src="trophy2" alt="" class="w-8">
          <div class="w-16 bg-tp-black rounded-full border-2 border-tp-yellow">
            <p class="font-bold text-center text-sm py-1">{{ rankingSongs[1]?.votes || 0 }}</p>
          </div>
          <PodiumSong 
            :mySong="mySong === rankingSongs[1]?.songId"
            :image="rankingSongs[1]?.image" 
            :artist="rankingSongs[1]?.artist" 
            :song="rankingSongs[1]?.songName" 
            :showBoostButton="mySong === -1 || mySong === rankingSongs[1]?.songId" 
            :position="2" 
            @onBoostClick="openRankingModal(rankingSongs[1]?.songId)" 
            :class="{'z-20': !modalVisible && !popupOpen}"
            :isDJ = "false"
          />
        </div>

        <div v-if="rankingSongs[0]?.songId" class="flex flex-col items-center gap-2">
          <div class="flex flex-col items-center gap-2">
            <img :src="trophy1" alt="" class="w-8">
            <div class="w-16 bg-tp-black rounded-full border-2 border-tp-yellow">
              <p class="font-bold text-center text-sm py-1">{{ rankingSongs[0]?.votes || 0 }}</p>
            </div>
            <PodiumSong 
              :mySong="mySong === rankingSongs[0]?.songId"
              :image="rankingSongs[0]?.image" 
              :artist="rankingSongs[0]?.artist" 
              :song="rankingSongs[0]?.songName" 
              :showBoostButton="mySong === -1 || mySong === rankingSongs[0]?.songId" 
              :position="1" 
              @onBoostClick="openRankingModal(rankingSongs[0]?.songId)" 
              :class="{'z-20': !modalVisible && !popupOpen}"
              :isDJ = "false"
            />
          </div>
        </div>

        <div v-if="rankingSongs[2]?.songId" class="flex flex-col items-center gap-2 mt-10">
          <img :src="trophy3" alt="" class="w-8">
          <div class="w-16 bg-tp-black rounded-full border-2 border-tp-yellow">
            <p class="font-bold text-center text-sm py-1">{{ rankingSongs[2]?.votes || 0 }}</p>
          </div>
          <PodiumSong 
            :mySong="mySong === rankingSongs[2]?.songId"
            :image="rankingSongs[2]?.image" 
            :artist="rankingSongs[2]?.artist" 
            :song="rankingSongs[2]?.songName" 
            :showBoostButton="mySong === -1 || mySong === rankingSongs[2]?.songId" 
            :position="3" 
            @onBoostClick="openRankingModal(rankingSongs[2]?.songId)" 
            :class="{'z-20': !modalVisible && !popupOpen}"
            :isDJ="false"
          />
        </div>
      </div>
      <InfoPopup :paragraphs="paragraphs" :isOpen="popupOpen" @closePopup="closePopup"/>
      <ConfirmFreeVoteModal
        v-if="confirmModalVisible" 
        @onCloseModal="handleCloseConfirmModal" 
        :class="[{'transition-opacity duration-300 opacity-100 z-20': confirmModalVisible}, {'opacity-0': !confirmModalVisible}]"
      />
      <RankingBoostModal
        v-if="modalVisible" 
        @onCloseModal="handleCloseModal" 
        @onClickFreeVote="handleFreeVote" 
        @onClickBoost="handleBoost" 
        :class="[{'transition-opacity duration-300 opacity-100 z-20': modalVisible}, {'opacity-0': !modalVisible}]"
        :availableFreeBoost="availableFreeBoost"
      />
      <AddVotesModal 
        v-if="boostModalVisible"
        :votePlans="votePlans" 
        @submitVotePlan="handlePayment" 
        @onCloseModal="handleCloseBoostModal" 
        :class="[{'transition-opacity duration-300 opacity-100 z-20': boostModalVisible}, {'opacity-0': !boostModalVisible}]"
      />
      <Timer class="absolute -bottom-6 left-1/2 transform -translate-x-1/2" v-if="!timerIsNull" :initialTime="timeLeft" />

    </div>
    <div class="flex-1 flex flex-col gap-4 items-center bg-tp-black rounded-t-3xl py-6 px-6 yellow-shadow">
      <BoostWarning 
        :alreadyHasSong="mySong!==-1"
        :mySongIsFirst="mySongIsFirst"
      />
      <RankingSong
        v-if="mySong === -1"
        :position="null" 
        :image="lens" 
        song="Tu canción" 
        artist="¡Toca aquí!"
        :addSong=true
        @addSong="handleAddSong"
      />
      <RankingSong 
        v-for="(rankingSong, index) in rankingSongs.slice(3)" 
        :key="index" 
        :position="index + 4" 
        :image="rankingSong.image" 
        :song="rankingSong.songName" 
        :artist="rankingSong.artist"
        :votes="rankingSong.votes" 
        :mySong="mySong === rankingSong.songId"
        :songNotSelectedYet = "mySong ===-1"
        @addSong="handleAddSong" 
        @onBoostClick="openRankingModal(rankingSong.songId)"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import Timer from '@/components/Common/Timer.vue';
import PodiumSong from '@/components/Ranking/PodiumSong.vue';
import RankingSong from '@/components/Ranking/RankingSong.vue';
import InfoPopup from '@/components/Common/InfoPopup.vue';
import BoostWarning from '@/components/Ranking/BoostWarning.vue';
import RankingBoostModal from '@/components/Ranking/RankingBoostModal.vue';
import AddVotesModal from '@/components/Common/AddVotesModal.vue';
import ConfirmFreeVoteModal from '@/components/Ranking/ConfirmFreeVoteModal.vue';
import lens from '@/assets/images/lupa.png';
import logo from '@/assets/images/inverted-logo.png';
import info from '@/assets/images/info-icon.png';
import trophy1 from '@/assets/images/trophy-1-outlined.png';
import trophy2 from '@/assets/images/trophy-2-outlined.png';
import trophy3 from '@/assets/images/trophy-3-outlined.png';
import user from '@/assets/images/user-icon.png';
import votingService from '../service/votingService';
import { useRoute, useRouter } from 'vue-router';
import { useVotePlans } from '@/composables/votePlans.js';

export default {
  name: 'Ranking',
  components: {
    Timer,
    PodiumSong,
    RankingSong,
    InfoPopup,
    BoostWarning,
    RankingBoostModal,
    ConfirmFreeVoteModal,
    AddVotesModal
  },
  props: {
    loungeDetails: Object
  },
  emits: ['addSong', 'payment'],
  setup(props) {
    const popupOpen = ref(false);
    const modalVisible = ref(false);
    const confirmModalVisible = ref(false);
    const boostModalVisible = ref(false);
    const songSelected = ref(null);
    const detailsLoaded = ref(false);
    const details = ref(null);
    const rankingSongs = ref([]);
    const cachedSongInfo = ref(new Map());
    const route = useRoute();
    const loungeId = route.params.id;
    const loungeVotingService = votingService(loungeId);
    const timeLeft = ref(null);
    const mySong = ref(-1);
    const router = useRouter();
    const availableFreeBoost = ref (null);
    const mySongIsFirst = ref (false);
    const timerIsNull = ref (true);
  
    const updateRanking = async (songsData) => {
      rankingSongs.value = songsData.map(song => {
        return {
          ...song.songInfo,
          votes: song.votos
        };
      })
    };
    onMounted(() => {
      if (props.loungeDetails && props.loungeDetails.public && props.loungeDetails.public.ranking) {
        const songsData = Object.values(props.loungeDetails.public.ranking.songs);
        const songIds = songsData.map(song => song.songInfo.songID);
        updateRanking(songsData, songIds);
        if (props.loungeDetails.public.ranking.timeLeft === null){
          timerIsNull.value=true;
        }
        else{
          timerIsNull.value=false;
          timeLeft.value = props.loungeDetails.public.ranking.timeLeft
        }
        detailsLoaded.value=true
        mySong.value = props.loungeDetails.private.mySong;
      }
    });

    watch(() => props.loungeDetails, (newDetails) => {
      if (newDetails && newDetails.public && newDetails.public.ranking) {
        details.value = newDetails;
        const songsData = Object.values(details.value.public.ranking.songs);
        const songIds = songsData.map(song => song.songInfo.songID);

        mySong.value = newDetails.private.mySong;

        availableFreeBoost.value = newDetails.private.availableFreeBoost;

        if (songIds.length > 0) {
          updateRanking(songsData, songIds);
        }
        if (newDetails.public.ranking.timeLeft === null){
          timerIsNull.value=true;
        }
        else{
          timerIsNull.value=false;
          timeLeft.value = newDetails.public.ranking.timeLeft;
        }
        
        
        
        
      }
    }, { deep: true });

    const openPopup = () => {
      console.log('abriendo popup')
      popupOpen.value = true;
    };

    const closePopup = () => {
      popupOpen.value = false;
    };

    const openRankingModal = (songId) => {
      if (mySong.value === -1 || mySong.value ===songId){
        songSelected.value = songId;
        modalVisible.value = true;
      }
    };

    const handleCloseModal = () => {
      modalVisible.value = false;
    };

    const handleCloseConfirmModal = () => {
      confirmModalVisible.value = false;
    };

    const handleConfirmFreeVote = () => {
      confirmModalVisible.value = true;
      handleCloseModal();
    };

    const handleFreeVote = () => {
      console.log('Free vote for song ID:', songSelected.value);
      try {
        loungeVotingService.rankingFreeBoost(songSelected.value);
      } catch (error) {
        console.log(error);
      }
      handleConfirmFreeVote();
    };

    const handleBoost = () => {
      modalVisible.value = false;
      boostModalVisible.value = true;
    };

    const handleCloseBoostModal = () => {
      boostModalVisible.value = false;
    };

    const handleFinishedRanking = () => {
      // Logic for handling finished ranking
    };

    const handleAddSong = () => {
      router.push(`/lounge/${loungeId}/add-song`)
    };

    const handlePayment = (votePlan) => {
      router.push(`/lounge/${loungeId}/song/${songSelected.value}/pay/${votePlan.price}/ranking`);
    };

    const { votePlans } = useVotePlans();

    return {
      lens,
      logo,
      info,
      trophy1,
      trophy2,
      trophy3,
      user,
      rankingSongs,
      openPopup,
      closePopup,
      popupOpen,
      openRankingModal,
      handleCloseModal,
      handleCloseConfirmModal,
      modalVisible,
      confirmModalVisible,
      handleFreeVote,
      handleBoost,
      boostModalVisible,
      handleCloseBoostModal,
      handleFinishedRanking,
      handleAddSong,
      handlePayment,
      songSelected,
      details,
      detailsLoaded,
      cachedSongInfo,
      timeLeft,
      mySong,
      availableFreeBoost,
      votePlans,
      mySongIsFirst,
      timerIsNull,
      paragraphs: [
        '¿Ves alguna canción que quieras escuchar? ¡Toca sobre ella y vótala para que se ponga arriba en el ranking!',
        '¿Quieres escuchar una que no está en el ranking? Toca sobre tu canción y añádela al ranking, ¡así la gente podrá votarla!',
        '¡La canción que vaya en primer lugar cuando se acabe la cuenta atrás será la que suene!',
        '¡No te preocupes! Si tu canción no gana algún ranking se te devolverá el dinero.'
      ]
    };
  }
};
</script>

<style scoped>
.yellow-shadow {
  box-shadow: 1px 1px 20px #f9f971 !important;
}
</style>