<template>
    <div class="flex justify-between gap-4 items-center bg-tp-yellow text-tp-black font-semibold py-3 px-4 rounded-xl">
        <p >Panel de control</p>
        <DJActionButton :darkbg="true" :image=arrow />
    </div>
</template>

<script>
import DJActionButton from './DJActionButton.vue';
import arrow from '@/assets/images/arrow-right-yellow.png';

export default {
  name: 'DJControlPanel',
  components: {
    DJActionButton
  },
  setup() {

    return {
        arrow
    };
  }
}

</script>