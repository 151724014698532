<template>
    <div class="modal-overlay"> 
      <div class="relative"> 
        <img class="absolute w-4 h-4 top-3 right-3 z-10" :src="close" alt="close-icon" @click="emitCloseModal">
        <div class="flex flex-col items-center justify-center border-4 border-tp-yellow bg-gradient-to-br from-tp-black to-tp-dark-green rounded-2xl p-7 max-w-md mx-auto">
            <p class="text-tp-yellow font-bold text-3xl text-center mb-4">¿Estás seguro de que deseas eliminar esta canción del ranking?</p>
            <p class="text-tp-yellow font-bold text-center mb-4">** No podrás revertirlo, y no cobrarás el dinero que haya obtenido</p>
            <button 
                class="flex justify-between items-center gap-10 bg-tp-yellow text-tp-black font-bold text-xl p-3 rounded-xl mb-4" 
                @click="emitRejectSong">
                <p class="text-tp-black font-bold text-sm">Sí, eliminar</p>
            </button>
            <button 
                class="inline-flex justify-center bg-tp-yellow text-tp-black font-bold text-xl px-6 py-3 rounded-xl" 
                @click="emitCloseModal">
                <p class="text-tp-black font-bold text-sm">Mejor no</p>
            </button>
        </div>
      </div> 
    </div> 
  </template>
  

<script>

import close from '@/assets/images/close-icon.png';

export default {
    name: 'DJConfirmRejectSong',
    props: {
        songId: Number
    },
    setup(props, { emit }) {

        const emitRejectSong = () => {
            emit('onRejectSong', props.songId); 
        };

        const emitCloseModal = () => {
            emit ('onCloseModal');
        }

        return {
            close,
            emitRejectSong,
            emitCloseModal
        }
    }
}
</script>
  