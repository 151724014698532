<template>
  <div class="border-2 border-tp-yellow rounded-2xl">
    <div class="bg-tp-yellow rounded-t-xl py-3">
      <p class="text-tp-black text-center font-bold">Mi perfil</p>
    </div>
    <div class=" flex flex-col gap-4 bg-tp-black rounded-b-xl px-8 py-6">
      <DJInput :icon=user label="Nombre" :model="djData.stage_name" />
      <!-- TODO :actionIcon=pencil-->
      <DJInput :icon=mail label="Correo" :model="djData.email" />
      <DJInput :icon=dollar label="Saldo actual" :model="djData.saldo" :actionIcon=withdraw @click="handleWithdrawal"/>
      <!-- TODO :actionIcon=withDraw-->
    </div>
  </div>  
</template>
  
  <script>
  import DJInput from '@/components/DJ/DJInput.vue';
  import mail from '@/assets/images/mail.png';
  import pencil from '@/assets/images/pencil.png';
  import dollar from '@/assets/images/dollar.png';
  import withdraw from '@/assets/images/withdraw.png';
  import user from '@/assets/images/user-outline.png';
  import { ref} from 'vue';
  import { sendAskedWithdrawal } from '../../service/djService';

  export default {
    name: 'DJProfile',
    components: {
      DJInput
    },
    props: {
      djData: Object
    },
    setup(props) {
      const saldoIsBiggerThan50 = ref (props.djData.saldo > 50)
      
      function updateProfile() {
        alert('Perfil actualizado');
      }
      
      function handleWithdrawal() {
        if (!saldoIsBiggerThan50.value){
          alert('El saldo debe ser mayor de 50 para poder solicitar la retirada');
        } else{
          sendAskedWithdrawal(props.djData.email)
          alert('Se está procesando la retirada, lo intentaremos resolver lo antes posible. Por favor, no envíes otra solicitud durante el periodo de espera a no ser que se alargue por más de 1 semana');
        }
        
      }
      return {
        updateProfile,
        pencil,
        mail,
        dollar,
        user,
        withdraw,
        handleWithdrawal
      }
    }
  }
  
  
  </script>
  