<template>
  <div class="flex flex-col items-start gap-2">
    <div class="flex gap-2 items-center">
      <p class="font-bold text-xl">Ranking</p>
      <img v-if="isActive" :src="live" alt="" class="w-5 h-5 animate-bounce">
    </div> 
    <div v-if="isActive" class="flex justify-between items-center bg-tp-black border-2 border-tp-yellow p-4 rounded-xl w-full">
      <div class="flex flex-col gap-2 text-tp-yellow">
        <p class="text-lg font-semibold">Top 3 actual:</p>
        <p v-for="(votingSong, index) in rankingSongs" :key="index" class="font-medium w-40 xs:w-52 truncate">
          <span class="font-semibold">{{ index + 1 }}.&nbsp;</span> {{ votingSong?.songName }} - {{ votingSong?.artist }}
        </p>
      </div>
      <div v-if = "timerIsNull && !rankingFinished"
      @click="startCountdown"
       class="flex items-center bg-tp-yellow gap-1 py-2 px-3 rounded-full cursor-pointer">
        <p class="font-semibold text-tp-black">Lanzar</p>
        <img :src="clock" alt="" class="w-4 h-4">
      </div>
      <Timer v-if = "!timerIsNull" :initialTime="timeLeft" />
    </div>
    <p v-if="votingActive" class="text-tp-yellow font-medium">
      No se puede acceder al ranking mientras haya una 4-hits activa
    </p>
    <div v-else-if="!isActive" class="flex flex-col justify-center gap-2">
      <p class="font-medium">No hay ningún ranking activo</p>
      <button class="bg-tp-yellow text-tp-black font-bold py-3 px-6 rounded-xl w-max cursor-pointer" @click="handleCreateRanking">Crear ranking</button>
    </div>
    <DJControlPanel v-if="isActive" class="cursor-pointer" @click="handleControlPanelRedirection"/>
  </div>
</template>
  
<script>
  import DJControlPanel from './DJControlPanel.vue';
  import live from '@/assets/images/live.png';
  import clock from '@/assets/images/black-clock.png';
  import { useRouter } from 'vue-router';
  import Timer from '@/components/Common/Timer.vue';
  import { ref,  onMounted, watch } from 'vue';
  import { rankingCountdown } from '../../service/djService';

  export default {
    name: 'DJRankingSection',
    props: {
      rankingData: Object,
      loungeId: String,
    },
    components: {
      DJControlPanel,
      Timer
    },
    setup(props) {
      const router = useRouter();
      const detailsLoaded = ref (false);
      const timeLeft = ref(null);
      const timerIsNull = ref (null);

      const rankingSongs = ref (null);

      const isActive = ref (false);
      const rankingFinished = ref(false);

      const votingActive = ref(false);

      const handleControlPanelRedirection = () => {
        router.push(`/dj/active-session/${props.loungeId}/ranking`);
      };

      const updateRanking = async (songsData) => {
        rankingSongs.value = songsData.map(song => {
          return {
            ...song.songInfo,
            votes: song.votos
          };
        })
      };

      const startCountdown = async ()=> {
        try{
          const response = await rankingCountdown(props.loungeId);

          if (response.result === true){
            router.push(`/dj/active-session/${props.loungeId}/ranking`)
          }
          else{
            console.log(response.error)
          }
          
        }
        catch{
          console.log('error')
        }
      }

      const handleCreateRanking = ()=> {
        router.push(`/dj/create-session`)
      }

      onMounted(async () => {
          if (props.rankingData) {
            if (props.rankingData.status === "RANKING" || props.rankingData.status === "FINISHED_GENERAL_VOTE" || props.rankingData.status ==="FINISHED_RANKING"){
              if (props.rankingData.ranking){
                isActive.value = true;
                const songsData = Object.values(props.rankingData.ranking.songs);
                updateRanking(songsData);
                if (props.rankingData.ranking.timeLeft === null){
                  timerIsNull.value = true;
                }
                else{
                  timerIsNull.value=false;
                  timeLeft.value = props.rankingData.ranking.timeLeft;
                }
              }
              else{
                isActive.value = false;
              }             
              detailsLoaded.value=true;
            }
            else{
              isActive.value = false;              
            }
          }
          rankingFinished.value=props.rankingData.status === "FINISHED_RANKING";
          votingActive.value = props.rankingData.status === "GENERAL_VOTE";
        });

      watch(() => props.rankingData, (newRankingData) => {
        if (newRankingData) {
          if (newRankingData.status === "RANKING" || newRankingData.status === "FINISHED_GENERAL_VOTE" || props.rankingData.status ==="FINISHED_RANKING"){
            if(newRankingData.ranking){
              isActive.value = true;
              const songsData = Object.values(newRankingData.ranking.songs);
              const songIds = songsData.map(song => song.songInfo.songID);
              if (songIds.length > 0) {
                updateRanking(songsData);
              }
              if (newRankingData.ranking.timeLeft === null){
                timerIsNull.value = true;
              } else{
                console.log("timeleft setted")            
                timeLeft.value = newRankingData.ranking.timeLeft;
                timerIsNull.value = false;
              }
            } else{
              console.log("ranking not active")
              isActive.value = false;
            }                        
          } else{
            console.log("ranking not active")
            isActive.value = false;
          }
          rankingFinished.value=newRankingData.status === "FINISHED_RANKING";
          votingActive.value = newRankingData.status === "GENERAL_VOTE";
        }
      }, { deep: true });

      return {
        live,
        clock,
        handleControlPanelRedirection,
        timeLeft,
        rankingSongs,
        detailsLoaded,
        timerIsNull,
        isActive,
        startCountdown,
        rankingFinished,
        votingActive,
        handleCreateRanking
      };
    }
  }
  
</script>