<template>
    <div class="modal-overlay">
        <div class="relative">
            <div class="flex flex-col border-4 border-tp-yellow bg-tp-black rounded-2xl gap-6 p-10">
                <div class="flex flex-col gap-8">
                    <img class="absolute w-4 h-4 top-5 right-5 z-10" :src="close" alt="close-icon" @click="handleCloseModal">
                    <p class="text-tp-yellow text-4xl font-bold">¿Quieres unirte a TuParty?</p>
                    <form class="flex flex-col gap-8" @submit.prevent="signUp">
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="username">Nombre</label>
                            <div class="flex items-center gap-2">
                                <img :src=user alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="username" placeholder="Juan" v-model="userData.name">
                            </div>
                            <img :src=line alt="" class="w-60">
                            <span v-if="!userData.name && submitted" class="text-red-500 text-sm">Nombre es obligatorio</span>
                            <label class="text-xl" for="username">Apellido</label>
                            <div class="flex items-center gap-2">
                                <img :src=user alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="username" placeholder="Sánchez" v-model="userData.surname">
                            </div>
                            <img :src=line alt="" class="w-60">
                            <span v-if="!userData.surname && submitted" class="text-red-500 text-sm">Apellido es obligatorio</span>
                            <label class="text-xl" for="username">Teléfono</label>
                            <div class="flex items-center gap-2">
                                <img :src=phone alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="username" placeholder="+34 612345678" v-model="userData.phone">
                            </div>
                            <img :src=line alt="" class="w-60">
                            <span v-if="!userData.phone && submitted" class="text-red-500 text-sm">Teléfono es obligatorio</span>
                            <label class="text-xl" for="username">Email</label>
                            <div class="flex items-center gap-2">
                                <img :src=mail alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="username" placeholder="ejemplo@correo.com" v-model="userData.email">
                            </div>
                            <img :src=line alt="" class="w-60">
                            <span v-if="!userData.email && submitted" class="text-red-500 text-sm">Email es obligatorio</span>
                            <label class="text-xl" for="username">¿Cómo usarás Tuparty?</label>
                            <div class="flex flex-col gap-2">
                                <label>
                                    <input type="radio" v-model="userData.role" value="DJ"> Soy DJ
                                </label>
                                <label>
                                    <input type="radio" v-model="userData.role" value="disco"> Tengo una discoteca o garito
                                </label>
                            </div>
                            <span v-if="!userData.role && submitted" class="text-red-500 text-sm">Cómo usarás TuParty es obligatorio</span>
                            <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                                <label class="text-xl" for="instagram">Instagram y zona de influencia</label>
                                <div class="flex items-center gap-2">
                                    <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="instagram" placeholder="@Instagram, Madrid" v-model="userData.instagram">
                                </div>
                            </div>
                            <img :src=line alt="" class="w-60">
                            <label class="text-xl" for="username">¿Cómo nos has conocido?</label>
                            <div class="flex items-center gap-2">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold" type="text" id="username" placeholder="instagram, un DJ, web.." v-model="userData.referral">
                            </div>
                            <img :src=line alt="" class="w-60">
                        </div>           
                        <button type="submit" 
                            class="bg-tp-yellow text-tp-black font-bold text-lg px-20 py-2 rounded-full mt-4"
                            >Solicitar acceso
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, computed } from 'vue';
    import mail from '@/assets/images/mail.png';
    import line from '@/assets/images/green-line.png';
    import close from '@/assets/images/close-icon.png';
    import user from '@/assets/images/user-outline.png';
    import phone from '@/assets/images/phone.png';
    import { useRouter } from 'vue-router';
import { sendLeadEmail } from '../../service/djService';


    export default {
        name: 'DJRegisterModal',
        setup(props, { emit }) {
            const router = useRouter();
            const submitted = ref(false);


            const userData = ref({
                name: '',
                surname: '',
                email: '',
                role:'',
                phone:'',
                referral:'',
                instagram:''
            });

            const isFormValid = computed(() => {
                return userData.value.name && userData.value.surname && userData.value.email &&
                    userData.value.phone && userData.value.role;
            });

            
            const signUp = async () => {
                console.log("entra")
                submitted.value = true;
                if (isFormValid.value){
                    await sendLeadEmail(userData.value);
                    router.push(`/dj/new/${userData.value.name}`);
                }
                
            };

            const handleCloseModal = () => {
                emit('onCloseModal');
            }

            return {
                signUp,
                userData,
                mail,
                line,
                close,
                handleCloseModal,
                user,
                phone,
                isFormValid,
                submitted
            }
        }
    }
</script>

<style scoped>
input::placeholder {
    color: #f9f871 !important;
}

</style>