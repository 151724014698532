<template>
  <div class="relative">
    <BoostButton v-if="selected && showBoostButton" :class="[{'z-10 absolute -top-2 -right-3 transition-opacity duration-500 opacity-100 animate-bounce': selected}, {'opacity-0': !selected}]" :selected="selected" @click="emitBoost"/>
    <div class="relative flex flex-col gap-2 justify-center items-center filter transition-all border-2 border-tp-yellow rounded-4xl overflow-hidden" :class="{'selected-shadow': selected}">
      <div v-if="progressPercentage > 0" :style="{backgroundColor: darkOverlayColor, '--progress-percentage': progressPercentage + '%' }" class="progress-overlay">
        <span class="progress-text">{{ progressPercentage }}%</span>
      </div>
      <div class="relative rounded-t-3xl overflow-hidden">
        <img class="object-cover border-b-2 border-tp-yellow" :class="[{'h-44': (position == 1 && timerFinished) || !timerFinished}, {'h-24': [2, 3, 4].includes(position) && timerFinished}]" :src="image">
      </div>
      <div :class="{'text-xs': [2, 3, 4].includes(position) && timerFinished}" class="flex flex-col w-full items-center text-center pb-2">
        <p class="font-bold" :class="[{'w-32 truncate': (position == 1 && timerFinished) || !timerFinished}, {'w-20 truncate': [2, 3, 4].includes(position) && timerFinished}]">{{ song }}</p>
        <p :class="[{'w-32 truncate': (position == 1 && timerFinished) || !timerFinished}, {'w-20 truncate': [2, 3, 4].includes(position) && timerFinished}]">{{ artist }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import BoostButton from '@/components/Common/BoostButton.vue';
export default {
  name: 'VotingSong',
  components: {
    BoostButton
  },
  props: {
    selected: Boolean,
    image: String,
    artist: String,
    song: String,
    progressPercentage: Number,
    darkOverlayColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.5)'
    },
    showBoostButton: Boolean,
    position: Number,
    timerFinished: Boolean
  },
  setup(props, { emit }) {
    console.log('progressPercentage: ', props.progressPercentage>=0);
    const emitBoost = () => {
      emit('onBoostClick');
    };

    return {
      emitBoost,
    };
  }
};
</script>

<style>
.selected-shadow {
  box-shadow: 1px 1px 10px #f9f971 !important;
}

.progress-overlay {
  position: absolute;
  width: var(--progress-percentage);
  top: 0;
  left: 0;
  bottom: 0;
  animation: slide 0.2s linear forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  z-index: 1;
}

.progress-text {
  color: #F9F871;
  font-weight: 600;
  transform: rotate(-90deg)
}

@keyframes slide {
  from {
    width: 0;
  }
  to {
    width: var(--progress-percentage);
  }
}
</style>
